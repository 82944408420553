import React from 'react'
import Toggle from 'react-toggled'
import { Fade, Zoom } from 'react-reveal'
import classNames from 'classnames'
import styled from 'styled-components'

import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'
import ActionButton from '../../components/ActionButton'

import './pricing.css'

class Pricing extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showPlansWithAdSupport: true
        }
    }

    render() {
        return (
            <Page
                className={classNames('pricing', this.props.className)}
                headerTitle="Plans &amp; Pricing"
                headerSubtitle="Start your internet radio station today!"
            >
                <PageSection>
                    <div
                        className={classNames(
                            'column',
                            'padded-section-content'
                        )}
                    >
                        <div className={classNames('ad-supported', 'column')}>
                            <h1 className={classNames('section-title')}>
                                Ad-supported?
                            </h1>
                            <p className={classNames('section-description')}>
                                Advertisements help offset the cost of royalties
                                for your station and allow you to lower your
                                monthly bill.
                            </p>
                            <Toggle
                                defaultOn={true}
                                onToggle={(on) =>
                                    this.setState({
                                        showPlansWithAdSupport: on
                                    })
                                }
                            >
                                {({ on, getTogglerProps }) => (
                                    <button
                                        className={classNames('toggle-button', {
                                            on: on
                                        })}
                                        {...getTogglerProps()}
                                    >
                                        <div className={classNames('option')}>
                                            Yes
                                        </div>
                                        <div className={classNames('option')}>
                                            No
                                        </div>
                                    </button>
                                )}
                            </Toggle>
                        </div>

                        <Grid className={classNames('plans')}>
                            {plans.map((plan, i) => (
                                <Fade key={i} duration={700} delay={300}>
                                    <Zoom
                                        key={i}
                                        duration={700}
                                        delay={i * 100}
                                    >
                                        <PricingPanel
                                            key={i}
                                            planData={plan}
                                            showWithAdSupport={
                                                this.state
                                                    .showPlansWithAdSupport
                                            }
                                        />
                                    </Zoom>
                                </Fade>
                            ))}
                        </Grid>

                        <p
                            className={classNames('section-description')}
                            style={{ textAlign: 'center' }}
                        >
                            If our packages are too small for your station,{' '}
                            <a href="mailto:support@live365.com">contact us</a>{' '}
                            about our Pro packages for customers above 7,000 TLH
                            per month.
                            <br />
                            <br />
                            For AM/FM Radio Broadcasters,{' '}
                            <a href="https://live365.com/go/am-fm-radio-streaming/">
                                click here
                            </a>
                            .
                        </p>
                    </div>
                </PageSection>

                {this.props.children}
            </Page>
        )
    }
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 100%);
    column-gap: 2%;
    row-gap: 2%;
    @media (min-width: 500px) {
        grid-template-columns: repeat(2, 45%);
    }
    @media (min-width: 1200px) {
        column-gap: 1rem;
        grid-template-columns: repeat(4, 24%);
    }
`

class PricingPanel extends React.Component {
    render() {
        let data = this.props.planData
        let withAdSupport = this.props.showWithAdSupport
        return (
            <table className={classNames('column', 'pricing-panel')}>
                <tbody>
                    <tr>
                        <th>{data.name}</th>
                    </tr>
                    <tr>
                        <td>
                            {!data.monetization ? (
                                <span className="price">Free</span>
                            ) : (
                                <span>
                                    <span className="price">
                                        $
                                        {withAdSupport
                                            ? data.priceWithAds
                                            : data.priceNoAds}
                                    </span>
                                    /month
                                </span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="order">
                            <OrderButton
                                pkgId={
                                    withAdSupport ? data.idAds : data.idNoAds
                                }
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>{data.storage} storage</td>
                    </tr>
                    <tr>
                        <td>{data.time} Licensed TLH</td>
                    </tr>
                    <tr>
                        <td>
                            {data.listeners ? data.listeners : 'Unlimited'}{' '}
                            Listeners
                        </td>
                    </tr>
                    <tr>
                        <td>Unlimited Bandwidth</td>
                    </tr>
                    <tr>
                        <td>128 kbps bitrate</td>
                    </tr>
                    <tr>
                        <td>Broadcast Live</td>
                    </tr>
                    <tr>
                        <td>Music Licensing (USA, Canada, UK)</td>
                    </tr>
                    <tr>
                        <td>
                            {data.monetization && withAdSupport ? (
                                <span>Monetization Included</span>
                            ) : (
                                <span>No Monetization</span>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>No Revenue Generation Restrictions (USA only)</td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

const OrderButton = (props) => (
    <ActionButton
        title="Order Now"
        link={
            'https://dashboard.live365.com/account/create-station?onboarding=1&package=' +
            props.pkgId
        }
        color="orange"
    />
)

const plans = [
    {
        idAds: 4,
        idNoAds: 4,
        name: '7-Day Trial',
        priceWithAds: 'Free',
        priceNoAds: 'Free',
        storage: '500 MB',
        time: '100',
        listeners: '5',
        monetization: false
    },
    {
        idAds: 1,
        idNoAds: 9,
        name: 'Broadcast 1',
        priceWithAds: '59',
        priceNoAds: '79',
        storage: '25 GB',
        time: '1,500',
        monetization: true
    },
    {
        idAds: 2,
        idNoAds: 10,
        name: 'Broadcast 2',
        priceWithAds: '99',
        priceNoAds: '134',
        storage: '50 GB',
        time: '3,500',
        monetization: true
    },
    {
        idAds: 3,
        idNoAds: 11,
        name: 'Broadcast 3',
        priceWithAds: '199',
        priceNoAds: '274',
        storage: '100 GB',
        time: '7,000',
        monetization: true
    }
]

export default Pricing
